@if (template.summary.state) {
  <div
    class="summary-part"
    [class.edit-mode]="selectedItem === template.summary"
    [class.has-icon]="template.summary.icon?.state"
    (click)="onSelect(template.summary)">

    <sf-tm-editor *ngIf="selectedItem === template.summary"/>

    <div class="summary-title-wrap" *ngIf="template.summary.title.state">

      @if (template.summary.icon?.state) {
        <i class="{{template.summary!.icon!.name}}"></i>
      }

      <h2
        contenteditable="true"
        [innerHTML]="template.summary.title.text"
        (blur)="onBlurSummaryTitle($event, template.summary)"
        (focus)="onFocusEL($event, template.summary.title.text)"
        [attr.placeholder]="template.summary.title.pl"
        (keydown.enter)="$event.preventDefault()"
        [sfContentEditableMaxLength]="appStateService.textMaxSizes.sectionMainTitle"
        [ngStyle]="{
           fontSize: appStateService.templateCurrentFontSize.summaryTitle + 'px',
           lineHeight: appStateService.templateCurrentFontSize.summaryTitleLineHeight + 'px',
           color: insideSidebar ? '#303030' : (selectedItem === template.summary ? tmTheme.summaryTitleFontEditModeColor : tmTheme.summaryTitleFontColor),
        }"
      ></h2>
    </div>

    <p
      contenteditable="true"
      (mouseup)="handleMouseUp(template.summary.desc)"
      (sfCtrlA)="handleCtrlA(template.summary.desc)"
      (keydown.enter)="onEnterDesc($event)"
      (keydown.backspace)="onBackspaceSummary($event)"
      (keydown.delete)="onBackspaceSummary($event)"
      [innerHTML]="template.summary.desc.text"
      (blur)="onBlurTmSummaryDesc($event)"
      (focus)="onFocusEL($event, template.summary.desc.text)"
      [attr.placeholder]="template.summary.desc.pl"
      [sfContentEditableMaxLength]="appStateService.textMaxSizes.summary"

      [ngStyle]="{
          fontSize: appStateService.templateCurrentFontSize.summaryContent + 'px',
          lineHeight: appStateService.templateCurrentFontSize.summaryContentLineHeight + 'px',
          color: insideSidebar ? '#303030' : (selectedItem === template.summary ? tmTheme.summaryFontEditModeColor : tmTheme.summaryFontColor),
      }"

      sfClickableLinks
    ></p>
    <!--  (paste)="appStateService.onPastElementText($event, appStateService.textMaxSizes.summary, appStateService.textMaxSizes.summaryMaxHeight)" -->
  </div>

  @if (hasUnderLine) {
    <div class="sf-line mb-0 pb-16"></div>
  }

}
